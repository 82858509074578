.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Toast z-index */
:root {
  -webkit-tap-highlight-color: transparent;
  --toastify-z-index: 99999999 !important;
  --navi-height: 48px;
  font-weight: unset;
  --Kontos-Blue: #413dec;
  --White: #fff;
  --Deep-25: #f5f5f6;
  --Deep-50: #edeef1;
  --Deep-100: #cccfd2;
  --Deep-200: #b2b6bc;
  --Deep-400: #80868f;
  --Deep-800: #1a2535;
  --Success: #10ce5c;
  --Error: #ff1e2b;
  --Warning: #faad14;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@font-face {
  font-family: "HarmonyOS Sans";
  src: url(./assets/fonts/HarmonyOS_Sans_Regular.ttf);
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "HarmonyOS Sans SC";
  src: url(./assets/fonts/HarmonyOS_Sans_Regular.ttf);
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "HarmonyOS Sans Bold";
  src: url(./assets/fonts/HarmonyOS_Sans_Bold.ttf);
  font-weight: 700;
  font-display: swap;
}

/* Scroller */
/* Set the width of the scroll bar */
::-webkit-scrollbar {
  width: 3px;
}

/* Set the style of the scrollbar track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Set the style of the scroll bar slider */
::-webkit-scrollbar-thumb {
  width: 3px;
  border-radius: 999px;
  background: var(--Deep-50, #edeef1);
}

/* Style when hovering over the scroll bar slider */
::-webkit-scrollbar-thumb:hover {
  background: #b2b6bc;
}

/* For Firefox */
/* * {
  scrollbar-width: thin;
  scrollbar-color: var(--Deep-50, #edeef1) transparent;
} */

.l {
  float: left;
}
.r {
  float: right;
}

button {
  padding: 0;
  border: none;
  @media (hover: hover) {
    &:hover {
      opacity: 0.9;
    }
  }
  &:active {
    opacity: 0.9;
  }
  cursor: pointer;
  background-color: transparent;
}
